import "./App.css";
import Content from "./view/Content";
import Header from "./view/Header";

function App() {
  return (
    <div className="App">
      <Header></Header>
      <Content></Content>
    </div>
  );
}

export default App;
