import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Service from "./view/Service";
import Questionnaire from "./view/Questionnaire";
import Login from "./view/Login";
import Test from "./view/Login/test.jsx";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/help" element={<App />} />
      <Route path="/service" element={<Service />} />
      <Route path="/questionnaire" element={<Questionnaire />} />
      <Route path="/login" element={<Login />} />
      <Route path="/test" element={<Test />} />
      <Route path="*" element={<Navigate to="/help"></Navigate>} />
    </Routes>
  </BrowserRouter>
);
