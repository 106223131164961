import { useEffect, useState } from "react";
import "./index.scss";
import useUrlState from "@ahooksjs/use-url-state";
import apis from "../../apis";
import { message } from "antd";

export default function Service(props) {
  const [url, setUrl] = useState("");
  const [state, setState] = useUrlState({});

  const getTokenApi = () => {
    let info = state.info;
    if (info) {
      info = JSON.parse(info);
    }
    const params = {
      user_name: info?.user_name,
      user_id: info?.user_id,
      extra_info: JSON.stringify(info),
    };
    apis.getTokenApi(params).then((res) => {
      if (res.code === 200) {
        const url = initUrl("czqfvOOTlA", res.data?.token);
        setUrl(url);
      } else {
        message.error(res.message);
      }
    });
  };

  useEffect(() => {
    getTokenApi();
    // const U = initUrl(
    //   "9OR4vTP8Ky",
    //   "dFBiVGN3bFpJQUUrVEJBdTNBLzhkYVZRZzVKS3RBSVp0dkRmbUN5T3hNNHh1SHlFcUUzVUVoa29Nam5JQzZVL1ZwOFlFNzIzOUJic2JTVm80amZicU5lRGVIRjRZTmJ1S0lwY0xEWGhvTVpKM0NBS0VMRHpFUktOUlNzeHNJd3YrRS9MNWFoSnFVbmlXQTIzZm11Wkd1YkZieG81NSs4T1V1dUlpYUxrOGdvQXMwVTloYStUUEtxSU9CR1ZaQWwxSzl5eFVrRzNBc2lERkdoZ2xMY0xNMm1IUmtVSkNKM3BxdkFoRW10WE1xTlV5U2lEMnNYTCtLTTRsbVBtNlpJNnFGZ0FETWE3NVhBSWxaTExWK2s5eHVOV2JrWEFveHA5VlpTaUdpU3IzMFR2Mm96dmFKempCQkZ5Q3lhbEtaWU5jcDNzN1Y1eCtDNjI1ZWI3ck5EK2xqK1NqWW9QUHBJSWtHM0d3RzRZY1E4eGM0UkM5RURGTUc5NEFzN1pPbTNZSnlyZzBzQUp3VnVxQ2lsWlRsRXN1YlF2WmVLc0tyK25Ia1hENkV6QjFIZnlVTlBvNEZwUUtucWdSMjNEZ3Fncg"
    // );
    // setUrl(U);
    document.title = "智能客服";
  }, []);

  const initUrl = (from, token) => {
    const href = "https://chatbot.aliyuncs.com/intl/index.htm?locale=zh-CN";
    return `${href}&from=${from}&_user_access_token=${token}`;
  };
  return (
    <div className="service">
      <iframe src={url} title="智能客服" frameborder="no"></iframe>
    </div>
  );
}
