import "./index.scss";
import {
  Dropdown,
  Space,
  Tree,
  Spin,
  message,
  Breadcrumb,
  Empty,
  Result,
  Input,
  Drawer,
} from "antd";
import { useState, useEffect, useMemo, useRef } from "react";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LoadingOutlined,
  CaretDownOutlined,
  ProfileOutlined,
  SearchOutlined,
  RightOutlined,
} from "@ant-design/icons";
import ReactMarkdown from "react-markdown";
import Scrollbars from "react-custom-scrollbars";
import useUrlState from "@ahooksjs/use-url-state";
import debounce from "lodash/debounce";
import remarkGfm from "remark-gfm"; // 划线、表、任务列表和直接url等的语法扩展
import rehypeRaw from "rehype-raw"; // 解析标签，支持html语法
import "github-markdown-css/github-markdown.css";
import img from "../../assets/暂无icon@2x.png";
import api from "../../apis/index";
import { useGetState, useHover } from "ahooks";

const LinkDom = (props) => {
  const { getMdFile, fileKey, allFile } = props;
  const [prevItem, setPrevItem] = useState(null);
  const [nextItem, setNextItem] = useState(null);

  const initDom = () => {
    const item = allFile?.find((_) => _.key === fileKey);
    if (item) {
      const _prevItem = allFile.find((_) => _.index === item?.index - 1);
      const _nextItem = allFile.find((_) => _.index === item?.index + 1);
      setPrevItem(_prevItem);
      setNextItem(_nextItem);
    } else {
      setPrevItem(null);
      setNextItem(null);
    }
  };

  useEffect(() => {
    initDom();
  }, [fileKey, allFile]);

  return (
    <div className="linkBox">
      <div>
        {prevItem && (
          <span
            onClick={() => {
              getMdFile(prevItem?.key);
            }}
          >
            <ArrowLeftOutlined /> 上一篇: {prevItem.title}
          </span>
        )}
      </div>
      <div>
        {nextItem && (
          <span
            onClick={() => {
              getMdFile(nextItem?.key);
            }}
          >
            下一篇: {nextItem.title} <ArrowRightOutlined />
          </span>
        )}
      </div>
    </div>
  );
};

export default function Content(props) {
  const [projectList, setProjectList] = useState([]);
  const [projectName, setProjectName, getProjectName] = useGetState(null);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [mdFile, setMdFile] = useState(null);
  const [allFile, setAllFile, getAllFile] = useGetState([]);
  const [loading, setLoading] = useState(false);
  const [allLoading, setAllLoading] = useState(false);
  const [state, setState] = useUrlState({});
  const [treeData, setTreeData] = useState([]);
  const [treeKey, setTreeKey] = useState(Math.random());
  const [notFile, setNotFile] = useState(false);
  const [fileItem, setFileItem] = useState({});

  const [debounceTimeout, setDebounceTimeout] = useState(500);
  const [options, setOptions] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [searchFocus, setSearchFocus] = useState(false);

  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);

  const ref = useRef(null);
  const isHovering = useHover(ref);
  /* 获取项目列表 */
  const getProjectList = () => {
    api.getProjectList().then((res) => {
      if (res.code === 200) {
        setProjectList(res.data);
        setProjectName(state?.projectName || res.data?.at(0));
        getDocInfo(state?.projectName || res.data?.at(0));
      } else {
        message.error(res.message);
        setProjectList([]);
        setAllLoading(false);
      }
    });
  };
  /* 获取文件列表 */
  const getDocInfo = (project_name, first) => {
    api.getDocInfo({ project_name }).then((res) => {
      if (res.code === 200) {
        let data = res.data;
        data = data?.map((item) => {
          item.topLevel = true;
          return item;
        });
        setTreeData(data);
        initFile(res.data, first);
      } else {
        message.error(res.message);
        setTreeData([]);
      }
      setTreeKey(Math.random());
      setAllLoading(false);
    });
  };

  useEffect(() => {
    setAllLoading(true);
    getProjectList();
  }, []);

  useEffect(() => {
    document.title = `使用说明-${projectName}`;
  }, [projectName]);

  /* 选中某个文件 */
  const onSelect = (keys) => {
    let fileItem = allFile?.find((_) => _.key === keys?.at(0));
    if (!fileItem) {
      return;
    }
    getMdFile(fileItem?.key);
  };

  /* 获取文件详情 */
  const getMdFile = (fileKey) => {
    setLoading(true);
    setNotFile(false);
    setState({ fileKey });
    setSelectedKeys([fileKey]);
    const item = getAllFile().find((_) => _.key === fileKey);
    setFileItem(item);
    if (!item?.file_url) {
      setNotFile(true);
      setLoading(false);
      return;
    }
    // const file = require(`../../assets/mdFiles/${fileKey}.md`);
    fetch(item?.file_url)
      .then((res) => res.text())
      .then((text) => {
        setMdFile(text);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  /* 获取所有文件列表 */
  const initFile = (items, first) => {
    let fileArr = [];
    const init = (files, parentKey, parentTitle) => {
      files?.forEach((item) => {
        item.parentKey = parentKey;
        item.parentTitle = parentTitle;
        fileArr.push(JSON.parse(JSON.stringify(item)));
        if (item.children?.length > 0) {
          init(item.children, item.key, item.title);
        }
      });
    };
    init(items, null);
    setAllFile(fileArr);
    setTimeout(() => {
      let fileKey = state?.fileKey || fileArr?.at(0)?.key;
      if (first) {
        fileKey = fileArr?.at(0)?.key;
      }
      getMdFile(fileKey);
    }, 100);
  };
  /* 格式化项目格式 */
  const initItems = (projectList) => {
    return projectList?.map((projectName) => ({
      key: projectName,
      label: (
        <div
          onClick={() => {
            setAllLoading(true);
            setProjectName(projectName);
            setState({ projectName });
            getDocInfo(projectName, true);
            onClose()
          }}
        >
          {projectName}
        </div>
      ),
    }));
  };

  const initBreadcrumb = (key, allFile, notDom = false) => {
    let fileItem = allFile?.find((_) => _.key === key);
    if (!fileItem) {
      return null;
    }
    let itemList = [];
    const func = (item) => {
      itemList.unshift(item);
      if (item?.parentKey) {
        let parentItem = allFile?.find((_) => _.key === item?.parentKey);
        func(parentItem);
      }
    };
    func(fileItem);

    if (notDom) {
      return itemList;
    }
    return (
      <Breadcrumb>
        <Breadcrumb.Item>帮助中心</Breadcrumb.Item>
        <Breadcrumb.Item>{projectName}</Breadcrumb.Item>
        {itemList?.map((_) => (
          <Breadcrumb.Item key={_.key}>
            <a
              className={key === _.key ? "activeBreadcrumbItem" : ""}
              onClick={() => {
                getMdFile(_.key);
              }}
            >
              {_.title}
            </a>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  };

  const searchSelect = (value) => {
    // const [title, key] = value?.split("&&&") || [];
    // setSearchValue(title);
    getMdFile(value);
  };

  const searchChange = (value) => {
    if (!value) {
      setOptions([]);
      return;
    }
    setSearchLoading(true);
    api.searchInfo(value, getProjectName()).then((res) => {
      if (res.code === 200) {
        const data = res?.data || [];
        setOptions([...data]);
      } else {
        message.error(res.message);
        setOptions([]);
      }

      setSearchLoading(false);
    });
    // setTimeout(() => {
    //   setOptions([
    //     {
    //       value: "01_果冻绘写简介&&&01_果冻绘写简介",
    //       label: (
    //         <div className="searchOptionItemBox">
    //           <h4>01_果冻绘写简介</h4>
    //           <div>11111</div>
    //         </div>
    //       ),
    //     },
    //     {
    //       value: "05_结构化解析&&&03_果冻绘写Pro网页版/05_结构化解析",
    //       label: (
    //         <div className="searchOptionItemBox">
    //           <h4>05_结构化解析</h4>
    //           <div>22222</div>
    //         </div>
    //       ),
    //     },
    //     {
    //       value:
    //         "01_快速创建报告&&&04_果冻绘写Pro插件版/02_撰写工作台/01_快速创建报告",
    //       label: (
    //         <div className="searchOptionItemBox">
    //           <h4>01_快速创建报告</h4>
    //           <div>33333</div>
    //         </div>
    //       ),
    //     },
    //   ]);
    //   setSearchLoading(false);
    // }, 1000);
  };

  const debounceFetcher = useMemo(() => {
    return debounce(searchChange, debounceTimeout);
  }, [debounceTimeout]);

  const searchTextFunc = () => {
    setSearchDrawerOpen(true);
  };

  const onClose = () => {
    setSearchDrawerOpen(false);
    setSearchValue(null);
    setOptions([])
  };

  const replaceStr = (str, keyWord) => {
    let reg = new RegExp(keyWord, "gi");
    return str?.replace(reg, `<span style='color:#0068b2;'>${keyWord}</span>`);
  };

  const sliceStrByKey = (str, keyWord) => {
    const maxLen = 70;
    const strLen = str?.length;
    const keyIndex = str?.indexOf(keyWord);
    if(keyIndex === -1){
      if(strLen > maxLen){
        return str?.slice(0, maxLen) + '...'
      }
      return str?.slice(0, maxLen)
    }else{
      let startIndex = keyIndex;
      let minIndex = keyIndex - maxLen + keyWord?.length;
      minIndex = minIndex < 0 ? 0 : minIndex;
      for (let index = keyIndex; index >= minIndex; index--) {
        const word = str[index];
        if([',','.','!',';','?','。','，','！','？','；']?.includes(word)){
          startIndex = index + 1
          break
        }
        if(index === 0){
          startIndex = 0
        }
      }
      let endIndex = startIndex + maxLen;
      return str?.slice(startIndex, endIndex) + (strLen > endIndex ? '...' : '')
    }
  };

  const initFilesDoms = (list) => {
    const projectName = getProjectName();
    return list?.map((item) => {
      let title = replaceStr(item.title || "", searchValue);
      let content = sliceStrByKey(item.content || "", searchValue);
      content = replaceStr(content || "", searchValue);

      return (
        <div
          className="fileItemContent"
          onClick={() => {
            searchSelect(item.id);
          }}
        >
          <div
            className="fileItemTitle"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          ></div>
          <div
            className="fileItemText"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          ></div>
          <div className="fileItemLink">
            来自：
            <>
              {projectName} <RightOutlined />
            </>
            {initBreadcrumb(item.id, allFile, true)?.map((_, index) => {
              return (
                <>
                  {index > 0 ? <RightOutlined /> : null}
                  <span
                    className="fileItemLinkSpan"
                    onClick={(e) => {
                      searchSelect(_.key);
                      e.stopPropagation();
                    }}
                  >
                    {_.title}
                  </span>
                </>
              );
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="myContent">
      {/* 加载遮罩层 */}
      {allLoading && (
        <div className="loadingModel">
          <Spin size="large" />
        </div>
      )}

      <div
        className="searchFixedBox"
        style={{
          width: "40px",
        }}
        ref={ref}
      >
        <span className="iconSpanBox" onClick={searchTextFunc}>
          <SearchOutlined />
        </span>
        {/* <AutoComplete
          options={options}
          onSelect={searchSelect}
          onSearch={(value) => {
            setSearchValue(value);
            debounceFetcher(value);
          }}
          size="large"
          onBlur={() => {
            setSearchFocus(false);
            setOptions([]);
          }}
          onFocus={() => {
            setSearchFocus(true);
            searchChange(searchValue);
          }}
          value={searchValue}
        >
          <Input
            size="large"
            suffix={searchLoading ? <LoadingOutlined /> : <SearchOutlined />}
            value={searchValue}
            placeholder="输入关键字搜索"
          ></Input>
        </AutoComplete> */}
      </div>

      <div className="catalogueBox">
        <div className="headerLogo">
          <div className="projectName">{projectName}</div>
          <div className="moreProjects">
            <Dropdown
              menu={{
                items: initItems(projectList),
              }}
              onClick={(e) => e.preventDefault()}
            >
              <Space>
                <ProfileOutlined />
                更多产品
                <CaretDownOutlined />
              </Space>
            </Dropdown>
          </div>
        </div>
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{
            height: "calc(100% - 48px)",
            width: "100%",
            // padding:'20px 0'
          }}
        >
          <div className="catalogueTreeBox">
            <Tree
              treeData={treeData}
              selectedKeys={selectedKeys}
              defaultExpandAll={true}
              key={treeKey}
              onSelect={(selectedKeys) => {
                if (selectedKeys?.length === 0) {
                  return;
                }
                onSelect(selectedKeys);
              }}
              titleRender={(node) => {
                // const style = {
                //   fontWeight: node?.file_url ? null : "bold",
                // };
                let className = "treeItem";
                if (node?.file_url) {
                  className += " fileItem";
                }
                return (
                  <div className={className}>
                    <span
                      style={{ marginRight: node?.file_url ? "5px" : null }}
                      className="treeItemImgSpan"
                    >
                      {
                        node?.file_url ? (
                          // <FileTextOutlined />
                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAqCAYAAADBNhlmAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASUSURBVHgBzVndbts2FD60JP/EzUXSLE3TbMmGxo6DppgXoE+xm94Fu+6AdE+Rp9i6B9jVgMYd1ldJchG3Wd0Cw4AmQIfYsWWJIylTochDSs7coh8giDqkyE+H5xweUgCfOUhOXQlmB8quGKYERpDcb7eXvChaZOyCOI5LhBCaaRGyK1DKcP1MKSWyPR2xcplQIRt7VwDD93t7excHBweFiRoEtx49uh1fXq7xTlEyAUIUkyukZdV4TKjnxf8cHx//DYlGpwZptVqbm5vb33Zfv/6FzghRFNE3vd5vrdbD75oPHjxkWIaC5qM3ItT3q/yTv9nY2IcZgPGDMAzhy7W1Hw47vz8l43EwovROu92+DW4fEPC1Z+OFwWAgBkkbEJIOrMr4M1bH0e/3oVKpQLPReNJ58QK+f/z45/6I3t3a2opPTk7OwTHdhdQsB1YHlzKdnJTJi9cxR0vrOMk/nz//idmiR6l/b3t7e8E1tk6QukjKS5Wpd9t7OprN5pPDw8OnUSnymUmt7uzscJKkCEEiPDAE52AuQrZ3dDQajR9fdjr7YT8ss8c1RvoWICTxKQ7wQeT06jaGaVVCb2uQfNnZHwzGQalSWWfTXYccgjSNaxZgZKQN6hrGzAIn+cc+825/PPaM8GN6sRZ0sWl1aQyTe54HvV4PhsNhxmG4fH5+HlZWlvdY6HlWrrMQlxCMbQSB+mwFGYIVp91Xhmxj/SvwfR9sRMvlstCyvEs5L8/NzXH5vBj7KuLkMl9s9ErYcqTboNrhvdVVo85GTq3n2sJQrVZl/zEWQ/SeE3JXWWKiYvLltVrVWqdCjY9qnNTfQUyEuggmrRUNYh2phGwDuVYarL2w/ZrCwULQAOYE0zqJq12KABfnEtRx9tcbuCm+WFqCen0Or+QaLGCDSUPHCr24sABcGYbZSQVN5FJhVJFVKmWrqQgN+gUI2sKM7DgIAoUJMUmqjDKss0Fdt0kbfIQIGmZkh2/fvTNWEL0NRoKXV+4si8Cs1iPIcRJtivWvbGzeh1kB9WTNEk1ry1mLrZ1DsSzHtjYTfWM2Aa5BbS1Wp+LDh3+vbQ3jM5HfqtfF6mGbTp1kTGnJCyCCnECdkIuU8bSOzy8uoAjKzJlqtVqGEO/LFthFYEh2kgUCtTLFekdfb6zDNCgS1JV6Y5pneXLw/4HYfqGMWkURR7hJW2PzP4GZzeQMmGfsaUeOVB+NgUXXYmGoYU5nDqjpP7ZaONIsdIqNdAtLWKc0dGdbR/ZDqUcL7uoKBGrXYFOQSsv0+lQsJw5y5DiJbftpgyvjTmFJt/ClLoec63Qhb/dnSzTEuMnYxE0wNEnZnvP2HS5CaKJQwEnQaO4ydoyUjaSrP4FEg+5sRiSsNwRBThdcbQ1yiQanW4tt+9mPggIa5M4+4oVXZ2fP4BOh2+3+KgphyDcbGYLGPOzu7i5dXo7usrM7L3MYHubcwSzTgM1kqBxIWQ7g+Z8Akhywvz06Ojp3EuQydjS7yGxkke2QKqmQO48yCFV/N0z+CMhVSH2W7YQsmLSR709+UbCTojDq989PT0/fg/Yv5T8/vkuy+blNcAAAAABJRU5ErkJggg==" />
                        ) : null //<FolderOpenOutlined />
                      }
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        fontWeight: node?.topLevel ? "bold" : "unset",
                      }}
                    >
                      {node.title}
                    </span>
                  </div>
                );
              }}
            />
          </div>
        </Scrollbars>
      </div>

      <div className="articleBox">
        {allFile?.length === 0 ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Empty image={img} description="暂无数据" />
          </div>
        ) : (
          <>
            <div className="otherBox">
              <div>{initBreadcrumb(selectedKeys?.at(0), allFile)}</div>
              <div className="mdTitle">
                {allFile?.find((_) => _.key === selectedKeys?.at(0))?.title}
              </div>
              {/* <Divider></Divider> */}
            </div>
            <Scrollbars
              autoHide={true}
              autoHideTimeout={5000}
              style={{
                height: "calc(100% - 215px)",
                width: "100%",
                margin: "10px 0",
              }}
            >
              {loading ? (
                <div className="loadingBox">
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                        }}
                        spin
                      />
                    }
                  />
                </div>
              ) : (
                <div className="markdown-body">
                  {!notFile ? (
                    <ReactMarkdown
                      children={mdFile}
                      remarkPlugins={[remarkGfm, { singleTilde: false }]}
                      rehypePlugins={[rehypeRaw]}
                    ></ReactMarkdown>
                  ) : (
                    <ul>
                      {fileItem?.children?.map((item) => (
                        <li key={item.index}>
                          <a
                            href="javascript:;"
                            onClick={() => {
                              getMdFile(item.key);
                            }}
                          >
                            {item.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </Scrollbars>
            <div className="otherBox">
              {/* <Divider></Divider> */}
              <LinkDom
                allFile={allFile}
                items={projectList}
                fileKey={selectedKeys?.at(0)}
                getMdFile={getMdFile}
              ></LinkDom>
            </div>
          </>
        )}
      </div>

      <Drawer
        title="输入文档关键字查找"
        width={500}
        placement="right"
        onClose={onClose}
        visible={searchDrawerOpen}
        mask={false}
        maskClosable={true}
        className="searchDrawerClass"
      >
        <Input
          size="large"
          prefix={searchLoading ? <LoadingOutlined /> : <SearchOutlined />}
          value={searchValue}
          placeholder="输入文档关键字查找"
          className="searchDrawerInput"
          onChange={(e) => {
            let value = e.target.value;
            setSearchValue(value);
            debounceFetcher(value);
          }}
        ></Input>
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{
            height: "calc(100% - 60px)",
            width: "100%",
          }}
        >
          {searchLoading ? (
            <div className="spinBox">
              <Spin></Spin>
            </div>
          ) : (
            <div className="fileLIstContent">
              <span className="fileLenText">
                搜索当前产品，共{options?.length}条结果
              </span>
              {initFilesDoms(options)}
            </div>
          )}
        </Scrollbars>
      </Drawer>
    </div>
  );
}
