import "./index.scss";
import { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Select, message } from "antd";
import Api from "../../apis/index";
import logo from '../../assets/logo白@2x.png'
export default function Questionnaire(props) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [count, setCount] = useState(5);

  const [form] = Form.useForm();
  const inputMap = useRef([
    {
      label: "公司名称",
      key: "enterprise",
      width: "calc(50% - 15px)",
      placeholder: "请输入您公司的名称",
    },
    {
      label: "联系人",
      key: "name",
      width: "calc(50% - 15px)",
      placeholder: "请输入您的名字",
    },
    {
      label: "联系电话",
      key: "telephone",
      width: "calc(50% - 15px)",
      placeholder: "请输入您的手机号码",
    },
    {
      label: "所在城市",
      key: "city",
      width: "calc(50% - 15px)",
      placeholder: "请输入您工作的城市名称",
    },
    {
      label: "咨询产品",
      key: "product",
      type: "select",
      placeholder: "请选择您想试用的产品",
      options: [
        {
          label: "果冻绘写（智能金融文档撰写工具）",
          value: "果冻绘写（智能金融文档撰写工具）",
        },
        {
          label: "iWudao魔方尽调（一站式智能尽调分析平台）",
          value: "iWudao魔方尽调（一站式智能尽调分析平台）",
        },
        {
          label: "iWudao资本透视（一级市场大数据分析平台）",
          value: "iWudao资本透视（一级市场大数据分析平台）",
        },
      ],
      width: "100%",
    },
  ]);
  const timer = useRef();
  const submitForm = () => {
    form?.validateFields().then((res) => {
      console.log(res);
      let params = {
        name: res.name,
        company_name: res.enterprise,
        city: res.city,
        phone: res.telephone,
        project_name: res.product,
      };
      Api.SetUserInfo(params).then((result) => {
        if (result.code === 200) {
          setIsSuccess(true);
          timer.current = setInterval(() => {
            setCount((prev) => prev - 1);
          }, 1000);
        } else {
          message.error(result.message);
        }
      });
    });
  };

  useEffect(() => {
    if (count === 0) {
      clearInterval(timer.current);
      setCount(5);
      closeWindows();
    }
  }, [count]);

  useEffect(() => {
    document.title = "免费试用信息填写";
  }, []);

  const closeWindows = () => {
    var browserName = navigator.appName;
    var browserVer = parseInt(navigator.appVersion);
    //alert(browserName + " : "+browserVer);

    //document.getElementById("flashContent").innerHTML = "<br>&nbsp;<font face='Arial' color='blue' size='2'><b> You have been logged out of the Game. Please Close Your Browser Window.</b></font>";

    if (browserName == "Microsoft Internet Explorer") {
      var ie7 =
        document.all && !window.opera && window.XMLHttpRequest ? true : false;
      if (ie7) {
        //This method is required to close a window without any prompt for IE7 & greater versions.
        window.open("", "_parent", "");
        window.close();
      } else {
        //This method is required to close a window without any prompt for IE6
        this.focus();
        window.self.opener = this;
        window.self.close();
      }
    } else {
      //For NON-IE Browsers except Firefox which doesnt support Auto Close
      try {
        this.focus();
        window.self.opener = this;
        window.self.close();
      } catch (e) {}

      try {
        window.open("", "_self", "");
        window.close();
      } catch (e) {}
    }
  };

  return (
    <div className="questionnaireContent">
      {/* <div className="headSplitDiv"></div> */}
      {/* isSuccess */}
      <img src={logo} alt="" className="logoImg"/>
      <div className="questionnaireForm">
        <div className="voteHeader">
          <h1 className="voteHeaderTitle">
            吾道科技（iWudao）产品免费试用申请！
          </h1>
        </div>
        {isSuccess ? (
          <div className="successBox">
            您的答卷已经提交,感谢您的参与!
            <span className="countDownBox">此页面将在 {count} 秒后关闭!</span>
          </div>
        ) : (
          <div className="questionnaireFormContent">
            <Form form={form} layout="vertical">
              {inputMap.current?.map((item) => (
                <Form.Item
                  label={item.label}
                  name={item.key}
                  key={item.key}
                  style={{ width: item.width }}
                  rules={[
                    {
                      required: true,
                      message: "请输入内容",
                    },
                  ]}
                >
                  {item.type === "select" ? (
                    <Select
                      options={item.options}
                      size="large"
                      placeholder={item.placeholder}
                    ></Select>
                  ) : (
                    <Input size="large" placeholder={item.placeholder} />
                  )}
                </Form.Item>
              ))}
            </Form>
            <div className="footerBtn">
              <Button onClick={() => submitForm()}>提交</Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
