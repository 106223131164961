import request from './request';

//获取项目列表
function getProjectList() {
    return request({
        url: '/help_api/project_list/',
        method: 'get',
    })
}

//获取用户信息
function getDocInfo(params) {
    return request({
        url: '/help_api/help_doc',
        method: 'get',
        params
    })
}

//获取用户信息
function getTokenApi(params) {
    return request({
        url: '/help_api/get_token',
        method: 'get',
        params
    })
}

//获取用户信息
function SetUserInfo(params) {
    return request({
        url: '/question/save',
        method: 'post',
        data: params
    })
}

//搜索信息
function searchInfo(key_words,project_name) {
    return request({
        url: `/help_api/search?key_words=${key_words}&project_name=${project_name}`,
        method: 'get',
    })
}

export default {
    getProjectList,
    getDocInfo,
    SetUserInfo,
    searchInfo,
    getTokenApi
}