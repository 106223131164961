import { useEffect, useState } from "react";
import "./index.scss";
import useUrlState from "@ahooksjs/use-url-state";
import request from "../../apis/request";

export default function LoginPage(props) {
  const [state, setState] = useUrlState({});
  
  useEffect(() => {
    if (state.code) {
      window.close()
      return;
    }
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1c5eed56beb7b786&redirect_uri=${window.encodeURIComponent(
      "https://x83y291166.goho.co/login"
    )}&response_type=code&scope=snsapi_userinfo&state=STATE&forcePopup=true#wechat_redirect`;
  }, []);

  return <div> {JSON.stringify(state)}</div>;
}
