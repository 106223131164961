import { useEffect, useState } from "react";
import "./index.scss";
import request from "../../apis/request";
import { Button, Input, Divider } from "antd";

export default function LoginPage(props) {
  const [code, setCode] = useState(null);

  const getToken = () => {
    request({
      url: `https://api.weixin.qq.com/sns/oauth2/access_token?appid=wx1c5eed56beb7b786&secret=5aeaefeeed77a18e330d8e140a0aae3b&code=021swcml234Mqc4BjTml2ZLdKS3swcmW&grant_type=authorization_code`,
      method: "get",
    }).then((res) => {
      console.log(res);
    });
  };

  return (
    <div style={{padding:'20px'}}>
      <Input value={code} style={{ width: "200px" }}></Input>

      <Divider />

      <Button
        onClick={() => {
          getToken();
        }}
      >
        获取Token
      </Button>
    </div>
  );
}
